<template>
  <div class="ylkj">
    <div class="ylkj_head">
      <div class="ylkj_head_nr">
        <a href="#/yl_index">
          <img
            :src="whData.wh_logo"
            alt=""
            style="width: auto;"
          />
        </a>
        <ul class="nr_list">
          <li
            v-for="(item, idx) in headData"
            @click="move(item.diyname)"
            :key="idx"
            :class="[fullpage.current == item.diyname ? 'li_active' : '']"
          >
            {{ item.name }}
          </li>
        </ul>
        <div
          class="nav_sp_nav"
          :class="open_show ? 'sp_nav_se' : ''"
          @click="open_show = !open_show"
        >
          <span v-for="el in 3" :key="el"></span>
        </div>
      </div>
    </div>
    <div class="ylkj_nr" ref="ylkj">
      <div
        class="ylkj-nav"
        ref="ylkjNav"
        @mousewheel="mouseWheelHandle"
        @DOMMouseScroll="mouseWheelHandle"
      >
        <div
          v-for="(item, idx) in wzjsList"
          :key="idx"
          class="ylkj-nav-nr"
          :style="{ height: isHeight + 'px' }"
          :id="'currentId' + (idx + 1)"
        >
          <div
            class="ylkj-nav-bg"
            :style="{ backgroundImage: `url(${item.bgUrl})` }"
          >
            <div class="qygwjs_nav" v-if="idx === 0">
              <div class="nav-texts">
                <div
                  class="qygwjs_nav_fadeInUp"
                  :class="inAnimation ? 'dh-fadeInUp' : ''"
                >
                  <h2>{{ item.标题 }}</h2>
                  <span>{{ item.zi_title }}</span>
                </div>
                <p
                  class="qygwjs_nav_text"
                  :class="inAnimation ? 'dh-fadeInUp' : ''"
                  v-html="item.text"
                ></p>
                <p
                  class="qygwjs_nav_text"
                  :class="inAnimation ? 'dh-fadeInUp' : ''"
                  v-html="item.text_yw"
                ></p>
                <div
                  class="qygwjs_nav_btn"
                  :class="inAnimation ? 'dh-fadeInUp' : ''"
                >
                  <div class="btn_img">
                    <img
                      :src="item.wh_ewm"
                      alt=""
                      style="width:100%;height:100%"
                    />
                  </div>
                  <a class="btn_nr" @click="onFanhuiTop(7)">
                    立即联系
                    <i class="el-icon-right right_icon"></i>
                  </a>
                </div>
              </div>
            </div>
            <div class="ppwzsj_nav" v-if="idx === 1">
              <div class="nav-texts">
                <div
                  class="qygwjs_nav_fadeInUp"
                  :class="inAnimation ? 'dh-fadeInUp' : ''"
                  v-for="(el, i) in item.fwList"
                  :key="i"
                >
                  <h2>{{ el.key }}</h2>
                  <h4>{{ el.value }}</h4>
                  <div class="line"></div>
                  <ul class="list_nav" v-if="i == 0">
                    <li v-for="(val, x) in item.fw_list" :key="x">
                      <span>{{ val.key }}</span>
                      <span>{{ val.value }}</span>
                    </li>
                  </ul>
                  <ul class="list_nav" v-if="i == 1">
                    <li v-for="(val, x) in item.fw_list2" :key="x">
                      <span>{{ val.key }}</span>
                      <span>{{ val.value }}</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="gwscwz_nav" v-if="idx === 2">
              <div class="left_info">
                <div
                  class="qygwjs_nav_fadeInUp"
                  :class="inAnimation ? 'dh-fadeInUp' : ''"
                >
                  <h2>{{ item.tj_title }}</h2>
                </div>
                <div
                  class="qygwjs_nav_text"
                  :class="inAnimation ? 'dh-fadeInUp' : ''"
                >
                  {{ item.tj_text }}
                </div>
                <div
                  class="qygwjs_nav_btn"
                  :class="inAnimation ? 'dh-fadeInUp' : ''"
                >
                  立即联系
                </div>
              </div>
              <div class="right_img" :class="inAnimation ? 'dh-fadeInUp' : ''">
                <img :src="item.tj_img" alt="" style="width:100%" />
              </div>
            </div>
            <div class="xcxwzjs_nav" v-if="idx === 3">
              <div class="nav_title" :class="inAnimation ? 'dh-fadeInUp' : ''">
                <h2>{{ item.xly_title }}</h2>
                <h4>{{ item.xly }}</h4>
              </div>
              <ul class="nav_list" :class="inAnimation ? 'dh-fadeInUp' : ''">
                <li v-for="(el, x) in item.xlyList" :key="x">
                  <h2>{{ el.key }}</h2>
                  <div class="li_text">
                    <div v-if="x != 2">
                      <video
                        width="100%"
                        height
                        controls
                        :poster="item.xlyVideo[x].key"
                      >
                        <source :src="item.xlyVideo[x].value" height />
                      </video>
                    </div>
                    <img :src="item.xly_img" alt="" v-else style="width:100%" />
                    {{ el.value }}
                  </div>
                </li>
              </ul>
              <div class="nav_btn" :class="inAnimation ? 'dh-fadeInUp' : ''">
                {{ item.xly_text }}
              </div>
            </div>
            <div class="yxwzjs_nav" v-if="idx === 4">
              <div class="nav_title" :class="inAnimation ? 'dh-fadeInUp' : ''">
                <h2>{{ item.dt }}</h2>
                <h4>{{ item.yw_title }}</h4>
              </div>
              <ul class="nav_list" :class="inAnimation ? 'dh-fadeInUp' : ''">
                <li v-for="(el, x) in dtList" :key="x">
                  <h2>{{ el.name }}</h2>
                  <div class="li_text" v-html="el.content"></div>
                </li>
              </ul>
              <div class="nav_btn" :class="inAnimation ? 'dh-fadeInUp' : ''">
                <span>查看更多</span>
                <i class="el-icon-right right_icon"></i>
              </div>
            </div>
            <div class="hymhwz_nav" v-if="idx === 5">
              <div class="nav_title" :class="inAnimation ? 'dh-fadeInUp' : ''">
                <h2>{{ item.yw_gywm }}</h2>
                <h4>{{ item.gywm }}</h4>
              </div>
              <video
                width="50%"
                height
                controls
                :poster="item.gy_img"
                :class="inAnimation ? 'dh-fadeInUp' : ''"
              >
                <source :src="item.gy_video" height />
              </video>
              <ul class="nav_list" :class="inAnimation ? 'dh-fadeInUp' : ''">
                <li v-for="(el, x) in item.gyList" :key="x">
                  <h2>{{ el.key }}</h2>
                  <div class="li_text" v-html="el.value"></div>
                </li>
              </ul>
            </div>
            <div class="xywysj_nav" v-if="idx === 6">
              <div
                class="nav_left"
                :class="inAnimation ? 'dh-bounceInLeft' : ''"
              >
                <h2>{{ item.lxwm }}</h2>
                <ul>
                  <li v-for="(el, x) in item.lxList" :key="x">
                    <p>{{ el.key }}</p>
                    <p>{{ el.value }}</p>
                  </li>
                </ul>
              </div>
              <div
                class="nav_right"
                ref="bdMap"
                :class="inAnimation ? 'dh-bounceInRight' : ''"
              >
                <img
                  :src="item.lx_dizhi"
                  alt=""
                  style="width:100%;height: 100%;"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="ylkj_nav">
      <div
        v-for="(item, idx) in wzjsList"
        :key="idx"
        class="ylkj-div"
        :style="{ backgroundImage: `url(${item.bgUrl})` }"
      >
        <div class="qygwjs_nav" v-if="idx === 0">
          <div class="nav-texts">
            <div
              class="qygwjs_nav_fadeInUp"
              :class="inAnimation ? 'dh-fadeInUp' : ''"
            >
              <h2>{{ item.标题 }}</h2>
              <span>{{ item.zi_title }}</span>
            </div>
            <p
              class="qygwjs_nav_text"
              :class="inAnimation ? 'dh-fadeInUp' : ''"
              v-html="item.text"
            ></p>
            <p
              class="qygwjs_nav_text"
              :class="inAnimation ? 'dh-fadeInUp' : ''"
              v-html="item.text_yw"
            ></p>
            <div
              class="qygwjs_nav_btn"
              :class="inAnimation ? 'dh-fadeInUp' : ''"
            >
              <div class="btn_img">
                <img :src="item.wh_ewm" alt="" style="width:100%;height:100%" />
              </div>
              <a class="btn_nr" @click="onFanhuiTop(7)">
                立即联系
                <i class="el-icon-right right_icon"></i>
              </a>
            </div>
          </div>
        </div>
        <div class="ppwzsj_nav" v-if="idx === 1">
          <div class="nav-texts">
            <div
              class="qygwjs_nav_fadeInUp"
              :class="inAnimation ? 'dh-fadeInUp' : ''"
              v-for="(el, i) in item.fwList"
              :key="i"
            >
              <h2>{{ el.key }}</h2>
              <h4>{{ el.value }}</h4>
              <div class="line"></div>
              <ul class="list_nav" v-if="i == 0">
                <li v-for="(val, x) in item.fw_list" :key="x">
                  <span>{{ val.key }}</span>
                  <span>{{ val.value }}</span>
                </li>
              </ul>
              <ul class="list_nav" v-if="i == 1">
                <li v-for="(val, x) in item.fw_list2" :key="x">
                  <span>{{ val.key }}</span>
                  <span>{{ val.value }}</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="gwscwz_nav" v-if="idx === 2">
          <div class="left_info">
            <div
              class="qygwjs_nav_fadeInUp"
              :class="inAnimation ? 'dh-fadeInUp' : ''"
            >
              <h2>{{ item.tj_title }}</h2>
            </div>
            <div
              class="qygwjs_nav_text"
              :class="inAnimation ? 'dh-fadeInUp' : ''"
            >
              {{ item.tj_text }}
            </div>
            <div
              class="qygwjs_nav_btn"
              :class="inAnimation ? 'dh-fadeInUp' : ''"
            >
              立即联系
            </div>
          </div>
          <div class="right_img" :class="inAnimation ? 'dh-fadeInUp' : ''">
            <img :src="item.tj_img" alt="" style="width:100%" />
          </div>
        </div>
        <div class="xcxwzjs_nav" v-if="idx === 3">
          <div class="nav_title" :class="inAnimation ? 'dh-fadeInUp' : ''">
            <h2>{{ item.xly_title }}</h2>
            <h4>{{ item.xly }}</h4>
          </div>
          <ul class="nav_list" :class="inAnimation ? 'dh-fadeInUp' : ''">
            <li v-for="(el, x) in item.xlyList" :key="x">
              <h2>{{ el.key }}</h2>
              <div class="li_text">
                <div v-if="x != 2">
                  <video
                    width="100%"
                    height
                    controls
                    :poster="item.xlyVideo[x].key"
                  >
                    <source :src="item.xlyVideo[x].value" height />
                  </video>
                </div>
                <img :src="item.xly_img" alt="" v-else style="width:100%" />
                {{ el.value }}
              </div>
            </li>
          </ul>
          <div class="nav_btn" :class="inAnimation ? 'dh-fadeInUp' : ''">
            {{ item.xly_text }}
          </div>
        </div>
        <div class="yxwzjs_nav" v-if="idx === 4">
          <div class="nav_title" :class="inAnimation ? 'dh-fadeInUp' : ''">
            <h2>{{ item.dt }}</h2>
            <h4>{{ item.yw_title }}</h4>
          </div>
          <ul class="nav_list" :class="inAnimation ? 'dh-fadeInUp' : ''">
            <li v-for="(el, x) in dtList" :key="x">
              <h2>{{ el.name }}</h2>
              <div class="li_text" v-html="el.content"></div>
            </li>
          </ul>
          <div class="nav_btn" :class="inAnimation ? 'dh-fadeInUp' : ''">
            <span>查看更多</span>
            <i class="el-icon-right right_icon"></i>
          </div>
        </div>
        <div class="hymhwz_nav" v-if="idx === 5">
          <div class="nav_title" :class="inAnimation ? 'dh-fadeInUp' : ''">
            <h2>{{ item.yw_gywm }}</h2>
            <h4>{{ item.gywm }}</h4>
          </div>
          <video
            width="50%"
            height
            controls
            :poster="item.gy_img"
            :class="inAnimation ? 'dh-fadeInUp' : ''"
          >
            <source :src="item.gy_video" height />
          </video>
          <ul class="nav_list" :class="inAnimation ? 'dh-fadeInUp' : ''">
            <li v-for="(el, x) in item.gyList" :key="x">
              <h2>{{ el.key }}</h2>
              <div class="li_text" v-html="el.value"></div>
            </li>
          </ul>
        </div>
        <div class="xywysj_nav" v-if="idx === 6">
          <div class="nav_left" :class="inAnimation ? 'dh-bounceInLeft' : ''">
            <h2>{{ item.lxwm }}</h2>
            <ul>
              <li v-for="(el, x) in item.lxList" :key="x">
                <p>{{ el.key }}</p>
                <p>{{ el.value }}</p>
              </li>
            </ul>
          </div>
          <div
            class="nav_right"
            ref="bdMap"
            :class="inAnimation ? 'dh-bounceInRight' : ''"
          >
            <img :src="item.lx_dizhi" alt="" style="width:100%;height: 100%;" />
          </div>
        </div>
      </div>
    </div>
    <div class="small_list" :style="{ right: open_show ? '-2px' : '-100%' }">
      <ul>
        <li
          v-for="(item, idx) in headData"
          :key="idx"
          @click="move(item.diyname)"
        >
          {{ item.name }}
        </li>
      </ul>
      <div class="list_menu_fot">
        <p>迅帆科技真诚期待您的来电</p>
        <a href="tel:020-39259989">
          <span>400-118-1615</span>
        </a>
      </div>
    </div>
    <div class="small_bg" v-if="open_show" @click="open_show = false"></div>
    <Quickly
      :currentData="currentData"
      @onFanhuiTop="onFanhuiTop(1)"
    ></Quickly>
  </div>
</template>
<script>
import Quickly from '@/common/quickly/quickly.vue'
import {
  getBasicMsg,
  getPageMsg,
  getMenuData,
  getXwData,
  getXwxqData
} from '@/api/api'
export default {
  components: {
    Quickly
  },
  data () {
    return {
      isHeight: 0,
      inAnimation: true,
      open_show: false,
      headData: [],
      fullpage: {
        current: 1, // 当前页面
        isScrolling: false, // 防止滚动多页
        deltaY: 0 // 判断往下往上
      },
      wzjsList: [{}, {}, {}, {}, {}, {}, {}],
      currentData: {},
      dtList: [],
      whData:{}
    }
  },
  methods: {
    getData () {
      this.isHeight = window.innerHeight
      let that = this
      getMenuData(this.changeData()).then(res => {
        if (res.data.code == 1) {
          this.headData = res.data.data
        }
      })
      getBasicMsg(this.changeData()).then(item => {
        if (item.data.code == 1) {
          let id = item.data.data.menu_id
          this.currentData = item.data.data
          this.getPage(id)
          getXwData(item.data.data.project_id).then(res => {
            if (res.data.code == 1) {
              this.dtList = []
              res.data.data.forEach(el => {
                if (el.id) {
                  this.getXwxq(el.name, el.news[0].id)
                }
              })
            }
          })
        }
      })
    },
    getXwxq (e, i) {
      getXwxqData(i).then(res => {
        if (res.data.code == 1) {
          let obj = {
            name: e,
            content: res.data.data.maincontent
          }
          this.dtList.push(obj)
        }
      })
    },
    getPage (i) {
      this.wzjsList = []
      getPageMsg(i).then(res => {
        if (res.data.code == 1) {
          this.whData = res.data.data.wh
          this.wzjsList.push(res.data.data.whcq)
          this.wzjsList.push(res.data.data.fwts)
          this.wzjsList.push(res.data.data.mftj)
          this.wzjsList.push(res.data.data.xly)
          this.wzjsList.push(res.data.data.dt)
          this.wzjsList.push(res.data.data.gywm)
          this.wzjsList.push(res.data.data.lxwm)
          this.wzjsList.forEach((el, idx) => {
            if (idx == 0) {
              el.bgUrl = el.bg_Image
            } else if (idx == 1) {
              el.bgUrl = el.fw_bg
            } else if (idx == 2) {
              el.bgUrl = el.tj_bg
            } else if (idx == 3) {
              el.bgUrl = el.xly_bg
            } else if (idx == 4) {
              el.bgUrl = el.dt_bg
            } else if (idx == 5) {
              el.bgUrl = el.gywm_bg
            } else if (idx == 6) {
              el.bgUrl = el.lx_bg
            }
          })
        }
      })
    },
    onFanhuiTop (i) {
      this.move(i)
      this.fullpage.current = i
    },
    next () {
      // 往下
      let len = this.wzjsList.length
      if (this.fullpage.current + 1 <= len) {
        this.fullpage.current += 1
        this.move(this.fullpage.current)
      }
    },
    pre () {
      // 往上
      if (this.fullpage.current - 1 > 0) {
        this.fullpage.current -= 1
        this.move(this.fullpage.current)
      }
    },
    move (index) {
      this.fullpage.current = index
      this.fullpage.isScrolling = true // 防止滚动多页
      this.directToMove(index)
      setTimeout(() => {
        // 延迟1s
        this.fullpage.isScrolling = false
        this.inAnimation = false
      }, 1010)
    },
    directToMove (index) {
      let height = this.$refs['ylkj'].clientHeight //获取屏幕的宽度
      let scrollPage = this.$refs['ylkjNav'] // 获取执行tarnsform的元素
      let scrollHeight
      scrollHeight = -(index - 1) * height + 'px'
      scrollPage.style.transform = `translateY(${scrollHeight})`
      this.inAnimation = true
    },
    mouseWheelHandle (event) {
      // 监听鼠标监听
      // 添加冒泡阻止
      let evt = event || window.event
      if (evt.stopPropagation) {
        evt.stopPropagation()
      } else {
        evt.returnValue = false
      }
      if (this.fullpage.isScrolling) {
        // 判断是否可以滚动
        return false
      }
      let e = event.originalEvent || event
      this.fullpage.deltaY = e.deltaY || e.detail // Firefox使用detail
      if (this.fullpage.deltaY > 0) {
        this.next()
      } else if (this.fullpage.deltaY < 0) {
        this.pre()
      }
    }
  },
  mounted () {
    this.getData()
  }
}
</script>
<style scoped lang="less">
.ylkj {
  min-height: 100vh;
  overflow: hidden;
  .ylkj_head {
    position: fixed;
    top: 20px;
    width: 100%;
    left: 0;
    z-index: 99;
    .ylkj_head_nr {
      width: 1200px;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .nr_list {
        display: flex;
        li {
          margin-left: 60px;
          height: 40px;
          line-height: 40px;
          position: relative;
          cursor: pointer;
          a {
            color: #000;
          }
        }
        .li_active {
          font-weight: bold;
          font-size: 18px;
        }
        .li_active::before {
          content: '';
          width: 40%;
          height: 2px;
          position: absolute;
          left: 0;
          right: 0;
          bottom: 0;
          background-color: #000;
          margin-left: auto;
          margin-right: auto;
        }
      }
      .nav_sp_nav {
        width: 1.4rem;
        position: relative;
        cursor: pointer;
        height: 0.8rem;
        display: none;
        span {
          display: block;
          background: #231815;
          width: 1rem;
          height: 0.1rem;
          position: absolute;
          left: 0.2rem;
          transition: all ease 0.35s;
        }
        span:nth-of-type(1) {
          top: 0px;
        }
        span:nth-of-type(2) {
          top: 0.4rem;
        }
        span:nth-of-type(3) {
          top: 0.75rem;
        }
      }
      .sp_nav_se {
        span:nth-of-type(1) {
          top: 0.4rem;
          transform: rotate(45deg);
        }
        span:nth-of-type(2) {
          width: 0;
        }
        span:nth-of-type(3) {
          top: 0.4rem;
          transform: rotate(-45deg);
        }
      }
    }
  }
  .ylkj_nr,
  .ylkj_nav {
    min-width: 1200px;
    min-height: 100%;
    overflow: hidden;
    .ylkj-nav {
      width: 100%;
      height: 100vh;
      transition: all linear 0.5s;
    }

    .ylkj-nav-nr {
      height: 100%;
      transition: all linear 0.5s;
    }

    .ylkj-nav-bg,
    .ylkj-div {
      background-size: 100% 100%;
      display: flex;
      align-items: center;
      height: 100%;
      .qygwjs_nav,
      .ppwzsj_nav,
      .gwscwz_nav,
      .xcxwzjs_nav,
      .yxwzjs_nav,
      .hymhwz_nav,
      .xywysj_nav {
        height: 100%;
        display: flex;
        align-items: center;
        width: 1200px;
        margin: 0 auto;
        overflow: hidden;
        min-width: 1200px;
        position: relative;
        .nav-imgs {
          width: 600px;
          height: 520px;
          background-size: auto 100% !important;
          position: relative;
          .qygwjs_nav_logo {
            width: 184px;
            animation-delay: 0.6s;
            bottom: 3px;
            position: absolute;
            left: 0;
          }
        }
        .nav-texts {
          width: 1200px;
          margin: 0 auto;
          .qygwjs_nav_fadeInUp {
            line-height: 1.5;
          }
          .qygwjs_nav_text {
            line-height: 1.5;
            font-size: 20px;
            margin: 15px 0;
          }
          .qygwjs_nav_btn {
            overflow: hidden;
            display: flex;
            position: relative;
            .btn_img {
              width: 50px;
              height: 50px;
            }
            .btn_nr {
              font-size: 22px;
              color: #333;
              position: absolute;
              bottom: 0;
              left: 70px;
              cursor: pointer;
              .right_icon {
                font-size: 24px;
                font-weight: 600;
                vertical-align: text-bottom;
                animation: toggleMove 0.8s infinite;
              }
            }
          }
        }
      }
      .qygwjs_nav {
        .qygwjs_nav_fadeInUp {
          font-size: 50px;
          line-height: 1.5;
          h2 {
            font-weight: 600;
            color: #000;
          }
        }
      }
      .ppwzsj_nav {
        .nav-texts {
          display: flex;
          justify-content: space-between;
          .qygwjs_nav_fadeInUp {
            text-align: center;
            line-height: 2;
            position: relative;
            h2 {
              font-size: 38px;
              color: #333;
            }
            h4 {
              font-size: 22px;
            }
            .line {
              height: 6px;
              width: 20px;
              background-color: #333;
              margin: 20px auto;
            }
            .list_nav {
              display: flex;
              justify-content: center;
              font-size: 15px;
              li {
                margin: 0 6px;
                background-color: #fff;
                color: #224f93;
                box-sizing: border-box;
                font-size: 15px;
                position: relative;
                overflow: hidden;
                z-index: 1;
                width: 60px;
                height: 60px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                line-height: 1.2;
                border: 1px #224f93 solid;
                border-radius: 50%;
                cursor: pointer;
              }
              li:hover {
                color: #fff;
              }
              li:before {
                content: '';
                position: absolute;
                z-index: -1;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                border-radius: 100%;
                background: #16468d;
                transform: scale(0);
                transition: transform;
                transition: 0.3s;
                transition-timing-function: ease-out;
              }
              li:hover:before {
                transform: scale(2);
                background: #16468d;
              }
            }
          }
        }
      }
      .gwscwz_nav {
        justify-content: space-between;
        .left_info {
          width: 42%;
          color: #fff;
          h2 {
            background-color: #16468d;
            font-size: 30px;
            display: inline-block;
            padding: 8px 30px;
            border-radius: 100px;
            margin-top: 30px;
          }
          .qygwjs_nav_text {
            font-size: 22px;
            line-height: 48px;
            margin-top: 36px;
            color: #333;
          }
          .qygwjs_nav_btn {
            width: 82px;
            background-size: 100% auto;
            background-repeat: no-repeat;
            height: 30px;
            display: inline-block;
            text-align: center;
            line-height: 30px;
            color: #ffffff;
            background-color: #000;
            font-size: 16px;
            cursor: pointer;
            margin-top: 20px;
          }
        }
        .right_img {
          max-width: 54%;
        }
      }
      .xcxwzjs_nav,
      .yxwzjs_nav,
      .hymhwz_nav {
        flex-direction: column;
        justify-content: center;
        .nav_title {
          text-align: center;
          h2 {
            text-align: center;
            color: #0d6db9;
            opacity: 0.7;
            font-size: 19px;
          }
          h4 {
            color: #333;
            font-size: 24px;
            font-weight: bold;
            text-align: center;
            margin: 10px 0 30px;
          }
        }
        .nav_list {
          display: flex;
          justify-content: space-between;
          width: 100%;
          li {
            padding: 20px 30px;
            width: calc(33.33% - 5px);
            box-sizing: border-box;
            margin-bottom: 10px;
            background: #eef5f9;
            transition: 0.4s;
            cursor: pointer;
            h2 {
              margin-bottom: 10px;
              font-weight: 600;
              color: #333;
            }
            .li_text {
              color: #666;
              font-size: 15px;
              line-height: 21px;
            }
          }
        }
        .nav_btn {
          width: 100%;
          padding: 20px 30px;
          box-sizing: border-box;
          margin-bottom: 10px;
          color: #ccc;
          background: #eef5f9;
          text-align: center;
          transition: 0.4s;
        }
        .nav_btn:hover,
        .nav_list li:hover {
          background: #0d6db9;
          h2,
          .li_text {
            color: #fff;
            /deep/p span {
              color: #fff !important;
            }
          }
        }
      }
      .yxwzjs_nav {
        .nav_btn {
          width: 162px;
          display: flex;
          align-items: center;
          background: rgba(0, 0, 0, 0);
          color: #333;
          border: 1px solid #fff;
          margin-top: 20px;
          cursor: pointer;
          span {
            margin-right: 10px;
          }
          .right_icon {
            font-weight: 600;
            vertical-align: text-bottom;
            animation: toggleMove 0.8s infinite;
          }
        }
        .nav_btn:hover {
          background: #fff;
        }
      }
      .hymhwz_nav {
        .nav_list {
          margin-top: 30px;
          li {
            width: calc(25% - 5px);
            background: #eef5f9;
          }
        }
      }
      .xywysj_nav {
        height: 450px;
        .nav_left {
          width: 40%;
          height: 100%;
          background-color: #09528d;
          color: white;
          display: flex;
          justify-content: center;
          flex-direction: column;
          padding: 20px 50px;
          box-sizing: border-box;
          h2 {
            font-weight: bold;
            font-size: 26px;
            margin-bottom: 30px;
          }
          ul li {
            margin-bottom: 20px;
            line-height: 1.5;
            p:nth-child(1) {
              font-size: 19px;
            }
          }
        }
        .nav_right {
          width: 60%;
          height: 100%;
        }
      }
      .footer_nav {
        width: 100%;
        height: 100%;
        background: #333;
        .footer_nav_dbinfo {
          overflow: hidden;
          padding-top: 141px;
          padding-bottom: 40px;
          ul {
            width: 86%;
            max-width: 1600px;
            min-width: 1150px;
            margin: 0 auto;
            display: flex;
            li {
              width: 32%;
              color: #999;
              padding: 0 20px;
              .li_img {
                width: 100%;
                height: 230px;
                padding-bottom: 30px;
              }
              h3 {
                border-bottom: 1px solid #ccc;
                font-weight: bold;
                font-size: 20px;
                margin-bottom: 10px;
                padding-bottom: 10px;
                color: #ccc;
              }
              /deep/p {
                text-align: justify;
                color: #999;
                font-size: 14px;
                line-height: 1.8;
              }
            }
          }
        }
      }

    }
    .index-footer {
      display: none !important;
    }
  }
  .ylkj_nav {
    display: none;
  }
  .small_nav {
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 2.6rem;
    width: 100%;
    height: 100%;
  }
  .small_list {
    background: #fff;
    position: fixed;
    top: 2.6rem;
    right: -100%;
    width: 65%;
    height: 100%;
    z-index: 1001;
    box-shadow: -5px 5px 10px #333;
    transition: all 0.4s ease;
    ul {
      margin: auto;
      text-align: center;
      li {
        position: relative;
        width: 100%;
        line-height: 1.8rem;
        border-bottom: 1px #e5e5e5 solid;
        box-sizing: border-box;
        color: #222;
        text-align: left;
        padding: 0 0.6rem;
        font-size: 0.7rem;
        .current_div {
          transition: all 0.3s ease;
          p {
            transition: all ease 0.5s;
          }
        }
        i {
          border: solid #222;
          display: inline-block;
          border-width: 0 1px 1px 0;
          padding: 0.16rem;
          transform: rotate(-45deg);
          position: absolute;
          right: 0.6rem;
          top: 0.52rem;
        }
      }
    }
    .list_menu_fot {
      text-align: center;
      margin-top: 1rem;
      p {
        font-size: 0.8rem;
        color: #999;
        margin-bottom: 0.5rem;
      }
      a {
        span {
          font-size: 1rem;
          color: #d51419;
          display: block;
        }
      }
    }
  }
  .small_bg {
    height: 100% !important;
    z-index: 1000;
    background: rgba(0, 0, 0, 0.9);
    width: 100% !important;
    position: fixed;
    top: 2.6rem;
    left: 0;
    right: 0;
    overflow: hidden;
  }
}
@keyframes toggleMove {
  0% {
    margin-left: -4px;
  }
  50% {
    margin-left: 0px;
  }
  100% {
    margin-left: -4px;
  }
}
</style>
<style scoped lang="less">
@import url('~@/assets/css/index/yl_media.less');
</style>
